import 'nodep-date-input-polyfill';
import FeatherIcons from './modules/feather-icons';
import ObjectFit from './modules/object-fit';
import ScrollEvent from './modules/scroll-event';
import Smoothscroll from './modules/smooth-scroll';
import Hamburger from './modules/hamburger';
import SideColumnMenu from './modules/sidecolumn-menu';
import TabOpen from './modules/tab-open';
import ChatScroll from './modules/chat-scroll';
import FlexTextarea from './modules/flex-textarea';
import Slider from './modules/swiper';
import ZoomImage from './modules/zoomimage';
import Tooltip from './modules/tooltip';
import IconToggle from './modules/icon-toggle';
import RemoveElement from './modules/remove-element';
import FixedInContents from './modules/fixed-incontents';
import TextCollapse from './modules/text-collapse';
import CheckboxAllcheck from './modules/checkcox-allcheck';
import CreditForm from './modules/credit-form';

class Index {
  constructor() {
    new FeatherIcons();
    new ObjectFit();
    new ScrollEvent();
    new Smoothscroll();
    new Hamburger();
    new SideColumnMenu();
    new TabOpen();
    new ChatScroll();
    new FlexTextarea();
    new Slider();
    new ZoomImage();
    new Tooltip();
    new IconToggle();
    new RemoveElement();
    new FixedInContents();
    new TextCollapse();
    new CheckboxAllcheck();
    new CreditForm();
  }
}

export default new Index();
